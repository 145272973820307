import React from "react";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { IoChevronDown } from "react-icons/io5";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import { Chakra_Styles } from "../../MyStore/MyStore";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";

export default function PaymentPaymentInfoTable({
  paymentInfoItems,
  setShowModal,
  setTargetID,
  setShowViewSection,
  setShowUpdateForm,
}) {
  return (
    <div>
      <CRUDTable>
        <tbody>
          {paymentInfoItems &&
            paymentInfoItems?.map((payment) => (
              <CRUDTableRow key={payment?._id}>
                <ShortTextCell
                  text={payment.paymentMethod == "bank-account" ? payment?.bankName  : "Credit Card"}
                  cellStyle={{ fontWeight: "600" }}
                />
                <ShortTextCell
                  text={payment.paymentMethod == "bank-account" ? payment?.bankAddress : payment?.cardBillingAddress}
                  cellStyle={{ fontWeight: "600" }}
                />
                <ShortTextCell
                  text={payment.paymentMethod == "bank-account" ? payment?.email : payment?.cardHolderName}
                  cellStyle={{ fontWeight: "600" }}
                />

                <td>
                  <Menu>
                    <MenuButton
                      textAlign={"left"}
                      as={Button}
                      rightIcon={<IoChevronDown />}
                      size="md"
                      bg="#322A5D30"
                      // color='white'
                      _hover={{
                        bg: "#322A5D",
                        color: "white",
                      }}
                      _active={{
                        bg: "#322A5D",
                        color: "white",
                      }}
                    >
                      Actions
                    </MenuButton>
                    <MenuList>
                      <ViewButton
                        setShowModal={setShowModal}
                        setShowViewSection={setShowViewSection}
                        targetID={payment._id}
                        setTargetID={setTargetID}
                      >
                        <Text {...Chakra_Styles.actionMenuItem}>
                          Payment Info
                        </Text>
                      </ViewButton>
                      <MenuDivider />
                      <EditButton
                        setShowModal={setShowModal}
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={payment?._id}
                        setTargetID={setTargetID}
                      >
                        <Text {...Chakra_Styles.actionMenuItem}>
                          Edit Payment Info
                        </Text>
                      </EditButton>
                    </MenuList>
                  </Menu>
                </td>
              </CRUDTableRow>
            ))}
        </tbody>
      </CRUDTable>
    </div>
  );
}
