import { HStack, Text } from "@chakra-ui/react";
import React from "react";
import CreateButton from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";

export default function ProductRequestHeader({
  productRequest,
  setShowCreateForm,
  setShowModal,
}) {
  return (
    <HStack justifyContent={"space-between"}>
      <HStack spacing="10" w="full" pb="5">
        <Text className="screen_heading" fontWeight="semibold">
          You Have {productRequest?.length} Product Requested
        </Text>
        <CreateButton
          screenTopicSingular={"Product Request"}
          setShowCreateForm={setShowCreateForm}
          setShowModal={setShowModal}
        />
      </HStack>
    </HStack>
  );
}
