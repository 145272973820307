import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import Register from "../AuthScreens/Register/Register";
import VerifyEmail from "../AuthScreens/VerifyEmail/VerifyEmail";
import MyStore from "../MyStore/MyStore";
import ProductAsset from "../ProductAsset/ProductAsset";
import Products from "../MyStore/Products/Products";
import ProductFeatures from "../ProductAsset/ProductFeatures/ProductFeatures";
import ProductSize from "../ProductAsset/ProductFeatures/ProductSize/ProductSize";
import ProductColor from "../ProductAsset/ProductFeatures/ProductColor/ProductColor";
import ProductNavLink from "../MyStore/Products/ProductNavLink/ProductNavlink";
import Size from "../MyStore/Products/ProductNavLink/Size/Size";
import Color from "../MyStore/Products/ProductNavLink/Color/Color";
import Order from "../Order/Order";
import OrderNavlink from "../OrderNavlink/OrderNavlink";
import PaymentCardInfo from "../PaymentInfo/PaymentCardInfo";

import Login from "../AuthScreens/Login/Login";
import RecoverPassOne from "../AuthScreens/components/RecoverPassOne/RecoverPassOne";
import RecoverPassThree from "../AuthScreens/components/RecoverPassThree/RecoverPassThree";
import OrderItems from "../Order/components/OrderItems/OrderItems";
import ProductRequest from "../ProductAsset/ProductFeatures/ProductRequest/components/ProductRequest";

export default function MainScreen({ seller }) {
  return (
    <section className={`screen-container ${seller ? "seller" : "auth"}`}>
      <Routes>
        <Route
          path="/login"
          element={
            !seller && !seller?.isVerified ? (
              <Login />
            ) : (
              <Navigate to={"/dashboard"} />
            )
          }
        />

        <Route
          path="/dashboard"
          element={!seller && !seller?.isVerified ? <Login /> : <Dashboard />}
        />
        <Route path="/register/*" element={<Register />} />

        <Route path="/verifyEmail/:token" element={<VerifyEmail />} />

        <Route path="/recover-pass-one" element={<RecoverPassOne />} />

        <Route
          path="/resetsellerPassword/:token"
          element={<RecoverPassThree />}
        />

        <Route
          path="/myStores"
          element={seller ? <MyStore /> : <Navigate to={"/dashboard"} />}
        />

        <Route
          path="/mystores/product-request"
          element={seller ? <ProductRequest /> : <Navigate to={"/login"} />}
        />

        <Route
          path="/mystores/productScreen/:storeId"
          element={seller ? <ProductAsset /> : <Navigate to={"/login"} />}
        />

        <Route
          path="/mystores/products/:storeId"
          element={seller ? <Products /> : <Navigate to={"/login"} />}
        />

        <Route
          path="/mystores/productsFeature/:storeId"
          element={seller ? <ProductFeatures /> : <Navigate to={"/login"} />}
        />
        <Route
          path="/mystores/productsSize/:storeId"
          element={seller ? <ProductSize /> : <Navigate to={"/login"} />}
        />

        <Route
          path="/mystores/productsColor/:storeId"
          element={seller ? <ProductColor /> : <Navigate to={"/login"} />}
        />

        <Route
          path="/"
          element={seller ? <Dashboard /> : <Navigate to={"/login"} />}
        />

        <Route
          path="/mystores/product/productScreen/:productId"
          element={seller ? <ProductNavLink /> : <Navigate to={"/login"} />}
        />

        <Route
          path="/mystores/products/size/:productId"
          element={seller ? <Size /> : <Navigate to={"/login"} />}
        />

        <Route
          path="/mystores/products/color/:productId"
          element={seller ? <Color /> : <Navigate to={"/login"} />}
        />

        {/* Order related component */}
        <Route
          path="/mystores/order/:storeId/:orderStatus"
          element={seller ? <Order /> : <Navigate to={"/login"} />}
        />

        <Route
          path="/mystores/orders/:storeId"
          element={seller ? <OrderNavlink /> : <Navigate to={"/login"} />}
        />

        <Route
          path="/mystores/orderItems/:orderId"
          element={seller ? <OrderItems /> : <Navigate to={"/login"} />}
        />

        {/* user payment ifo page routes */}

        <Route
          path="/paymentcardinfo"
          element={seller ? <PaymentCardInfo /> : <Navigate to={"/login"} />}
        />
      </Routes>
    </section>
  );
}
