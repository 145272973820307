import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";

export default function useFetchData({ type, fetchColor, fetchSize }) {
  const COLOR_API = "/product/productColor/getAllColorByType/";
  const SIZE_API = "/product/productSize/getAllSizeByType/";
  const api = (fetchColor && COLOR_API) || (fetchSize && SIZE_API);

  const [data, setData] = useState([]);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchColorways() {
      const { data } = await axiosInstance.get(`${api}${type}`);
      const colors = data.map((color) => {
        return {
          value: color._id,
          label: color.name,
        };
      });
      setData(colors);
    }
    fetchColorways();
  }, [type, api]);
  return data;
}
