import { HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import ChebLogo from "../../../../../Assets/cheb-logo.svg";

export default function LoginTop() {
  return (
    <VStack pb="10" align="flex-start">
      <HStack align={"center"}>
        <Text fontSize="x-large">Login to</Text>
        <Image
          // src="https://store.cheb.antopolis.xyz/assets/logo/cheb-logo.svg"
          src={ChebLogo}
          alt="logo"
          width={70}
        />
      </HStack>
      <Text fontSize="small">
        Login and manage your sneaker store on <b>CheB</b>
      </Text>
    </VStack>
  );
}
