import { useContext, useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { AppContext } from "../../../../AppContext/AppContext";

export default function useAllPaymentInfo() {
  const axiosInstance = useAxiosInstance();
  const { toggleFetch,dashboardFilter, earning } = useContext(AppContext);

  const [totalOrderValue, setTotalOrderValue] = useState("");
  const [totalPaidPayment, setTotalPaidPayment] = useState("");
  const [approvePaymentCount, setApprovePaymentCount] = useState("");
  const [allPaymentsToSeller, setAllPaymentToSeller] = useState([]);

  console.log("useEffect",dashboardFilter, "earning",earning)

  const isDateFilterEnable  = dashboardFilter.from !== null && dashboardFilter.to !== null

  useEffect(() => {
    async function fetchAndsetAllPaymentInfo() {
      try {
        const newParams = new URLSearchParams()
        if(earning) newParams.append("earning", earning);
        if (isDateFilterEnable) {
          newParams.append("from", dashboardFilter?.from);
          newParams.append("to", dashboardFilter?.to);
        }
        const { data } = await axiosInstance.get(`dashboardPaymentInfo?${newParams.toString()}`);
        setTotalOrderValue(data.totalOrderValue);
        setTotalPaidPayment(data.totalPaidValue);
        setApprovePaymentCount(data.paymentApprovalCount);
        setAllPaymentToSeller(data.allPaymentsToSeller);
      } catch (error) {
        console.log("error is", error);
      }
    }

    fetchAndsetAllPaymentInfo();
  }, [toggleFetch,dashboardFilter, earning]);

  return {
    totalOrderValue,
    totalPaidPayment,
    approvePaymentCount,
    allPaymentsToSeller,
  };
}
