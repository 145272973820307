import React from "react";
import CRUDTable from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ImageCell from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import { Button, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react";
import { IoChevronDown } from "react-icons/io5";
import ViewButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import { Chakra_Styles } from "../../../../MyStore/MyStore";

export default function OrderItemsTable({
  orderItems,
  setShowModal,
  setShowViewSection,
  setTargetID,
}) {
  return (
    <div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Product Name" />
          <CRUDth th="Quantity" />
          <CRUDth th="Price" />
          <CRUDth th="Size" />
          <CRUDth th="Image" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {orderItems?.map((item) => (
            <CRUDTableRow key={item?._id}>
              <ShortTextCell text={item?.item.name} />
              <ShortTextCell text={item?.quantity} />
              <ShortTextCell text={item?.total} />
              <ShortTextCell text={item?.size?.size?.name} />
              {/* <ImageCell
                imgSrc={item?.item.images && item.item.images[0]}
                imgAlt={"Product Image"}
              /> */}
              <ImageCell
                imgSrc={item?.item?.cardImage}
                imgAlt={"Product Image"}
              />
              <td>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDown />}
                    size="md"
                    bg="#322A5D30"
                    // color='white'
                    _hover={{
                      bg: "#322A5D",
                      color: "white",
                    }}
                    _active={{
                      bg: "#322A5D",
                      color: "white",
                    }}
                  >
                    Actions
                  </MenuButton>
                  <MenuList>
                    <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={item._id}
                      setTargetID={setTargetID}
                    >
                      <Text {...Chakra_Styles.actionMenuItem}>
                        Order Details
                      </Text>
                    </ViewButton>
                  </MenuList>
                </Menu>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
      </CRUDTable>
    </div>
  );
}
