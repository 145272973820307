import React from "react";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import { Button, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react";
import { IoChevronDown } from "react-icons/io5";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import { Chakra_Styles } from "../../../MyStore/MyStore";
import CRUDButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { FaTruck } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";

export default function OrderTable({
  orders,
  navigate,
  setShowModal,
  setShowViewSection,
  setTargetID,
  setShowProcessingOrder,
  setShowCancelOrder,
  orderStatus,
  buttonText,
  handleShowTrackingModal,
  fetchMoreData,
  hasMoreData,
}) {
  return (
    <div>
      {/* <InfiniteScroll
        dataLength={orders.length}
        next={fetchMoreData}
        hasMore={hasMoreData}
        loader={<div>Loading...</div>}
        endMessage={<div>No more products to display</div>}
      > */}
      <CRUDTable>
        <tbody>
          {orders?.map((order, index) => (
            <CRUDTableRow key={order?._id}>
              <ShortTextCell text={index + 1} />
              <ShortTextCell text={order?.customer && order?.customer.name} />
              <ShortTextCell
                text={new Date(order?.createdAt).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "2-digit",
                })}
              />
              <ShortTextCell text={"$" + Number(order?.subtotal).toFixed(2)} />
              <ShortTextCell
                text={
                  order?.orderStatus == "completed"
                    ? "Delivered"
                    : order?.orderStatus
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase())
                }
              />

              <td>
                {order?.trackingUrl && (
                  <FaTruck
                    onClick={() => handleShowTrackingModal(order?._id)}
                  />
                )}
              </td>
              <td>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<IoChevronDown />}
                    size="md"
                    bg="#322A5D30"
                    // color='white'
                    _hover={{
                      bg: "#322A5D",
                      color: "white",
                    }}
                    _active={{
                      bg: "#322A5D",
                      color: "white",
                    }}
                  >
                    Actions
                  </MenuButton>
                  <MenuList>
                    <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={order._id}
                      setTargetID={setTargetID}
                    >
                      <Text {...Chakra_Styles.actionMenuItem}>
                        Order Details
                      </Text>
                    </ViewButton>

                    <CRUDButton
                      dataTip="Product"
                      dataFor="Product Item"
                      handleClick={() =>
                        navigate("/mystores/orderItems/" + order._id)
                      }
                    >
                      <Text {...Chakra_Styles.actionMenuItem}>Order Items</Text>
                    </CRUDButton>

                    {(orderStatus === "processing" ||
                      (orderStatus === "toBeDelivered" && !order?.address) ||
                      (orderStatus === "shipped" && !order?.address)) && (
                      <CRUDButton
                        dataTip="Status"
                        dataFor="Order Status"
                        handleClick={() => {
                          setShowModal(true);
                          setShowProcessingOrder(true);
                          setTargetID(order._id);
                        }}
                      >
                        <Text {...Chakra_Styles.actionMenuItem}>
                          {buttonText()}
                        </Text>
                      </CRUDButton>
                    )}

                    {orderStatus !== "completed" &&
                      orderStatus !== "canceled" && (
                        <CRUDButton
                          dataTip="Status"
                          dataFor="Order Status"
                          handleClick={() => {
                            setShowModal(true);
                            setShowCancelOrder(true);
                            setTargetID(order._id);
                          }}
                        >
                          <Text {...Chakra_Styles.actionMenuItem}>Cancel</Text>
                        </CRUDButton>
                      )}
                  </MenuList>
                </Menu>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
      </CRUDTable>
      {/* </InfiniteScroll> */}
    </div>
  );
}
