import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import { ORDERS_API } from "../../../Utilities/APIs/APIs";
import { RiRepeatLine } from "react-icons/ri";

import "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ActionButtonsCell/ActionButtonsCell.css";
import { AppContext } from "../../../AppContext/AppContext";
import OrderHeading from "./components/OrderHeading";
import OrderTable from "./components/OrderTable/OrderTable";
import OrderModal from "./components/OrderModal/OrderModal";
import OrderTrackingModal from "./components/OrderModal/OrderTrackingModal";
import InfiniteScroll from "react-infinite-scroll-component";

function Order() {
  const { storeId, orderStatus } = useParams();
  const { seller, triggerFetch, toggleFetch } = useContext(AppContext);
  const [orders, setOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showProcessingOrder, setShowProcessingOrder] = useState(false);
  const [showCancelOrder, setShowCancelOrder] = useState(false);
  const [showTrackingModal, setShowTrackingModal] = useState(false);
  const [trackingUrl, setTrackingUrl] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);

  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchAndsetOrders() {
      const { data } = await axiosInstance.get(
        `${ORDERS_API}?store=${storeId}&orderStatus=${orderStatus}&page=${page}`
      );
      setOrders(data);
      // setOrders((prevOrders) => [...prevOrders, ...data]);
      if (!data.length) {
        setHasMoreData(false);
      }
    }
    fetchAndsetOrders();
  }, [axiosInstance, storeId, orderStatus, toggleFetch, page]);

  const fetchMoreData = () => {
    console.log("changes page occured");
    setPage((prevPage) => prevPage + 1);
  };

  function handleCloseTrackingModal() {
    setTrackingUrl(null);
    setShowTrackingModal(false);
  }
  function handleShowTrackingModal(orderId) {
    const trackingUrl = orders?.find(
      (order) => order?._id === orderId
    )?.trackingUrl;
    console.log(trackingUrl, orderId, "trackingUrl1");
    setTrackingUrl(trackingUrl);
    setShowTrackingModal(true);
  }

  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
    setShowProcessingOrder(false);
    setShowCancelOrder(false);
  }

  function buttonText() {
    switch (orderStatus) {
      case "processing":
        return "Confirm Order";

      case "toBeDelivered":
        return "Complete This";

      // case "shipped":
      //   return "Complete This";

      default:
        return <RiRepeatLine />;
    }
  }

  async function handleStatusChange(id) {
    switch (orderStatus) {
      case "processing":
        await axiosInstance.patch(
          ORDERS_API + "/toBeDeliveredFromProcessing/" + id,
          ""
        );
        triggerFetch();
        handleClose();

        return;

      // case "toBeDelivered":
      //   await axiosInstance.patch(
      //     ORDERS_API + "/shippedFromToBeDelivered/" + id,
      //     ""
      //   );
      //   triggerFetch();
      //   handleClose();

      //   return;
      case "toBeDelivered":
        await axiosInstance.patch(
          ORDERS_API + "/completedFromShipped/" + id,
          ""
        );
        triggerFetch();
        handleClose();

        return;

      case "shipped":
        await axiosInstance.patch(
          ORDERS_API + "/completedFromShipped/" + id,
          ""
        );
        triggerFetch();
        handleClose();

        return;

      default:
        return console.log("Status Not Changed");
    }
  }

  return (
    <>
      <div className="crud_holder">
        <OrderHeading orders={orders} />
        {/* <InfiniteScroll
          dataLength={orders?.length}
          next={fetchMoreData}
          hasMore={true}
          loader={<div>Loading...</div>}
          endMessage={<div>No more products to display</div>}
        > */}
        <OrderTable
          orders={orders}
          setShowModal={setShowModal}
          setTargetID={setTargetID}
          setShowViewSection={setShowViewSection}
          setShowProcessingOrder={setShowProcessingOrder}
          setShowCancelOrder={setShowCancelOrder}
          buttonText={buttonText}
          navigate={navigate}
          orderStatus={orderStatus}
          handleShowTrackingModal={handleShowTrackingModal}
          fetchMoreData={fetchMoreData}
          hasMoreData={hasMoreData}
        />
        {/* </InfiniteScroll> */}
      </div>

      <OrderModal
        handleClose={handleClose}
        showModal={showModal}
        showViewSection={showViewSection}
        showProcessingOrder={showProcessingOrder}
        showCancelOrder={showCancelOrder}
        setShowModal={setShowModal}
        setShowProcessingOrder={setShowProcessingOrder}
        setShowCancelOrder={setShowCancelOrder}
        targetID={targetID}
        orderStatus={orderStatus}
        seller={seller}
        triggerFetch={triggerFetch}
        handleStatusChange={handleStatusChange}
      />

      <OrderTrackingModal
        handleClose={handleCloseTrackingModal}
        showModal={showTrackingModal}
        trackingUrl={trackingUrl}
      />
    </>
  );
}

export default Order;
