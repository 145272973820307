import React, { useState, useContext, useEffect } from "react";
import useAllPaymentInfo from "./Hooks/useAllPaymentInfo";
import OrderCards from "./components/OrderCards";

import "./Dashboard.css";
import PaymentDetails from "./components/PaymentDetials/PaymentDetails";
import DateRangePickerMaster from "./DateRangePickerMaster";

import { HStack, Text } from "@chakra-ui/react";
import Select from "react-select";
import { AppContext } from "../../../AppContext/AppContext";
import ResetButton from "./ResetButton";
import BarChartGraph from "./BarChartGrapha";


const options = [
  { value: "all", label: "All" },
  { value: "Crypto", label: "Crypto" },
  { value: "fiat", label: "Fiat" },
];

const revenuIncome = [
  { value: "weekly", label: "This Week" },
  { value: "yearly ", label: "This Year" },
  { value: "monthly", label: "This Month" },

]

export const ReactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    width: "150px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px",
  }),
};

function Dashboard() {
  const {
    totalOrderValue,
    totalPaidPayment,
    approvePaymentCount,
    allPaymentsToSeller,
  } = useAllPaymentInfo();

  const { setEarning } = useContext(AppContext);
  const [selectedDates, setSelectedDates] = useState([null, null]);

  const [filter, setEarningFilter] = useState("all");
  const [revenue, setRevenuIncome] = useState("weekly");
  console.log("revenue58",revenue)

  useEffect(() => {
    setEarning("all");
  }, [setEarning]);

  const handleFilter = (selectedOption) => {
    setEarningFilter(selectedOption ? selectedOption.value : null);
    setEarning(selectedOption ? selectedOption.value : null);
  };

  const handleRevenueFilter = (selectedOption) => {
    setRevenuIncome(selectedOption ? selectedOption.value : null);
  };

  return (
    <div className="dashboard_crud_holder">
      <div className="dashboard-flex">
        <h3 className="page-title">Dashboard</h3>
        <div className="right-flex">
          <DateRangePickerMaster
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
          />
          <HStack>
            <Text className="form-lable">Sort By:</Text>
            <Select
              placeholder={"Sort By"}
              styles={ReactSelectStyles}
              maxMenuHeight={220}
              menuPlacement="auto"
              isClearable={false}
              value={options.find((option) => option.value === filter) || null}
              onChange={handleFilter}
              options={options}
            />
          </HStack>
          <ResetButton
            setEarningFilter={setEarningFilter}
            setSelectedDates={setSelectedDates}
          />
        </div>
      </div>

      <OrderCards
        totalOrderValue={totalOrderValue}
        totalPaidPayment={totalPaidPayment}
      />
      <PaymentDetails
        approvePaymentCount={approvePaymentCount}
        allPaymentsToSeller={allPaymentsToSeller}
      />

      <div className="dashboard-card">
        <div className="card-flex">
          <h2 className="card-title">
            Revenue Income
          </h2>
          <HStack>
            <Text className="form-lable">Sort By:</Text>
            <Select
              placeholder={"Sort By"}
              styles={ReactSelectStyles}
              maxMenuHeight={220}
              menuPlacement="auto"
              isClearable={false}
              value={revenuIncome.find((option) => option.value === revenue) || null}
              onChange={handleRevenueFilter}
              options={revenuIncome}
            />
          </HStack>
        </div>
        <BarChartGraph revenuFilter={revenue} />
      </div>
    </div>
  );
}

export default Dashboard;
