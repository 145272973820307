import { createContext, useState } from "react";
import useAxiosAuthInstance from "../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance";
import { LOGIN_API, REGISTER_API } from "../Utilities/APIs/AuthAPIs/AuthAPIs";
import { Text, VStack, useToast } from "@chakra-ui/react";

/**
 * @typedef {Object} ApiState
 * @property {'none' | 'login' | 'logout' | 'register'} type - The type of API action.
 * @property {'idle' | 'pending' | 'success' | 'error' } status - The status of the API action.
 */

/**
 * Initializes the API state with default values.
 * @returns {ApiState} The initial API state.
 */
function initializeApiState() {
  return {
    type: "idle", // You can set any default value you want here
    status: "idle", // You can set any default value you want here
  };
}

export const AppContext = createContext({});

function AppContextProvider({ children }) {
  const toast = useToast();
  const axiosAuthInstance = useAxiosAuthInstance();
  const [toggleFetch, setToggleFetch] = useState(false);
  const [dashboardFilter,setDashboardFilter] = useState({
    from:null,
    to:null,
  });
  const [earning, setEarning] = useState(null);


  const [seller, setSeller] = useState(
    JSON.parse(localStorage.getItem("seller"))
  );

  // const [isLoading, setIsLoading] = useState(false)
  const initialAPIState = initializeApiState();
  const [apiState, setAPIState] = useState(initialAPIState);

  async function register(sellerData) {
    try {
      setAPIState({
        type: "register",
        status: "pending",
      });
      const response = await axiosAuthInstance.post(REGISTER_API, sellerData);
      if (response.data) {
        // console.log('Got data:', response.status)
        setSeller(response.data);
        localStorage.setItem("seller", JSON.stringify(response.data));
        setAPIState({
          type: "register",
          status: "success",
        });
      }
    } catch (error) {
      console.log(error.message);
      setAPIState({
        type: "register",
        status: "error",
      });
      triggerToast({
        title: "Couldn't sign up",
        description: "Seller Already Exists on this email,Change Email Please.",
        status: "error",
      });

      setTimeout(() => setAPIState(initialAPIState), 2000);
    }
  }

  async function login(sellerData) {
    try {
      // setIsLoading(true)
      setAPIState({
        type: "login",
        status: "pending",
      });
      const response = await axiosAuthInstance.post(LOGIN_API, sellerData);

      console.log("response 85", response.data)

      if (response.data) {
        setSeller(response.data);
        localStorage.setItem("seller", JSON.stringify(response.data));
        // setIsLoading(false)
        setAPIState({
          type: "login",
          status: "success",
        });
      }
    } catch (error) {

      console.log("error 96", error.response.data.message)
      setAPIState({
        type: "login",
        status: "error",
      });
      triggerToast({
        title: "Couldn't login",
        description: error.response.data.message ? error.response.data.message :
          "Please check your credentials or fill up the entire form.",
        status: "error",
      });

      setTimeout(() => setAPIState(initialAPIState), 2000);
    }
  }

  function logout() {
    setSeller(null);
    localStorage.removeItem("seller");

    // navigate('/auth/login')
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  function triggerToast({ title, description, status }) {
    toast({
      duration: "1400",
      render: () => (
        <VStack
          py="3"
          px="5"
          bg={status === "error" ? "red.500" : "green.600"}
          align="left"
          spacing="1"
          color="white"
          borderRadius="md"
        >
          <Text fontSize="medium" fontWeight="semibold">
            {title}
          </Text>
          <Text>{description}</Text>
        </VStack>
      ),
    });
  }

  return (
    <AppContext.Provider
      value={{
        register,
        login,
        logout,
        seller,
        setSeller,
        toggleFetch,
        triggerFetch,
        apiState,
        setDashboardFilter,
        dashboardFilter,
        setEarning,
        earning
        // isLoading,
        // setIsLoading
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export { AppContextProvider };
