import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";

function ViewPaymentCardInfo({ targetID }) {
  const [storeInfo, setStoreInfo] = useState(null);
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndsetStoreInfo() {
      const { data } = await axiosInstance.get("cardInfo/" + targetID);
      setStoreInfo(data);
    }
    fetchAndsetStoreInfo();
  }, [targetID, axiosInstance]);

  return (
    <div className="data-card-main">
      {storeInfo?.paymentMethod == "bank-account" && (
        <>
          <div className="data-flex">
            <h1 className="data-lable">Bank Name</h1>
            <p className="data-value">{storeInfo?.bankName}</p>
          </div>

          <div className="data-flex">
            <h1 className="data-lable">Bank Address</h1>
            <p className="data-value">{storeInfo?.bankAddress}</p>
          </div>

          <div className="data-flex">
            <h1 className="data-lable">Account Holder Name</h1>
            <p className="data-value">{storeInfo.accountHolderName}</p>
          </div>

          <div className="data-flex">
            <h1 className="data-lable">Account Number</h1>
            <p className="data-value">{storeInfo.accountNumber}</p>
          </div>

          <div className="data-flex">
            <h1 className="data-lable">Routing Number</h1>
            <p className="data-value">{storeInfo.routingNumber}</p>
          </div>

          <div className="data-flex">
            <h1 className="data-lable">Email</h1>
            <p className="data-value">{storeInfo.email}</p>
          </div>

          <div className="data-flex">
            <h1 className="data-lable">Crypto Wallet</h1>
            {storeInfo.walletAddress && <p className="data-value">{storeInfo.walletAddress}</p>}
          </div>
        </>
      )}

      {storeInfo?.paymentMethod == "credit-debit-card" && (
        <>
          <div className="data-card-main">
            <div className="data-flex">
              <h1 className="data-lable">Card Holder Name</h1>
              <p className="data-value">{storeInfo?.cardHolderName}</p>
            </div>

            <div className="data-flex">
              <h1 className="data-lable">Card Number</h1>
              <p className="data-value">{storeInfo?.cardNumber}</p>
            </div>

            <div className="data-flex">
              <h1 className="data-lable">CardExpiryDate</h1>
              <p className="data-value">
                {storeInfo?.cardExpiryDate?.months !== "" &&
                storeInfo?.cardExpiryDate?.years != ""
                  ? `${storeInfo?.cardExpiryDate?.months}/${storeInfo?.cardExpiryDate?.years}`
                  : ""}
              </p>
            </div>

            <div className="data-flex">
              <h1 className="data-lable">Card Cvv</h1>
              <p className="data-value">{storeInfo?.cardCvv}</p>
            </div>

            <div className="data-flex">
              <h1 className="data-lable">Card Billing Address</h1>
              <p className="data-value">{storeInfo?.cardBillingAddress}</p>
            </div>

            <div className="data-flex">
              <h1 className="data-lable">Crypto Wallet</h1>
              {storeInfo?.walletAddress && <p className="data-value">{storeInfo?.walletAddress}</p>}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ViewPaymentCardInfo;
