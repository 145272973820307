import { HStack, Text, Button } from "@chakra-ui/react";
import React from "react";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

const options = [
  { value: "all", label: "All Store" },
  { value: "archive", label: "Archive" },
  { value: "active", label: "Active" },
];

export const ReactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    width: "150px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px",
  }),
};

export default function MyStoreHeader({
  setShowCreateForm,
  setShowModal,
  filter,
  handleFilter,
  stores,
}) {

  const navigate = useNavigate();

  return (
    <HStack justifyContent={"space-between"}>
      <HStack spacing="10" w="full" pb="5">
        <Text className="screen_heading" fontWeight="semibold">
          You have {stores?.length} Stores
        </Text>
        <CreateButton
          screenTopicSingular={"Store"}
          setShowCreateForm={setShowCreateForm}
          setShowModal={setShowModal}
        />

        <Button
          colorScheme='ghost'
          // rightIcon={<HiLogout />}
          bg='#322A5D30'
          color={'#322A5D'}
          _hover={{
            bg: '#322A5D50'
          }}
          fontSize='small'
          justifyContent='flex-start'
          paddingY='18px'
          onClick={() => navigate(`/mystores/product-request`)}
          leftIcon={<FaPlus />}

        >
          Product Request
        </Button>
      </HStack>

      <HStack>
        <Select
          placeholder={"Sort By"}
          styles={ReactSelectStyles}
          maxMenuHeight={220}
          menuPlacement="auto"
          // menuPortalTarget={menuPortalTarget}
          isClearable={false}
          value={options.find((option) => option.value === filter)}
          onChange={handleFilter}
          options={options}
        />
      </HStack>
    </HStack>
  );
}
