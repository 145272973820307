import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import useAxiosInstance from '../../../Utilities/Hooks/useAxiosInstance';

export default function BarChartGraph({ revenuFilter }) {
    const axiosInstance = useAxiosInstance();
    const [chartState, setChartState] = useState({
        series: [
            {
                name: 'Total Revenue',
                data: [],
            },
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                    show: true,
                    tools: {
                        download: false
                    }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '25%',
                    endingShape: 'rounded',
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                title: {
                    text: 'revenue income',
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "$ " + val.toFixed(0);  
                    },
                },
            },
        },
    });

    useEffect(() => {
        const fetchTotalRevenue = async () => {
            try {
                const response = await axiosInstance.get(`dashboardRevenueInfo/${revenuFilter}`);
                // Extract total revenue and days, rounding totalRevenue values
                const totalRevenue = response.data.data.map(item => Math.round(item?.totalRevenue));
                let xaxisData = []; 
                if (revenuFilter.trim() === "weekly") {
                    xaxisData = response.data.data.map(item => item?.Day); 
                } else if (revenuFilter.trim() === "yearly") {
                    xaxisData = response.data.data.map(item => item?.month);
                }
                else if (revenuFilter.trim() === "monthly") {
                    xaxisData = response.data.data.map(item => item?.day);
                }
                else {
                    console.error("Invalid revenuFilter value");
                }

                // Update chart state
                setChartState((prevState) => ({
                    ...prevState,
                    series: [
                        {
                            name: 'Total Revenue',
                            data: totalRevenue,
                        },
                    ],
                    options: {
                        ...prevState.options,
                        xaxis: {
                            categories: xaxisData,
                        },
                    },
                }));
            } catch (error) {
                console.error('Error fetching total revenue:', error);
            }
        };

        fetchTotalRevenue();
    }, [axiosInstance, revenuFilter]);

    return (
        <div>
            <div id="chart">
                <ReactApexChart options={chartState.options} series={chartState.series} type="bar" height={350} />
            </div>
        </div>
    );
}
