import React, { useEffect, useState } from "react";
import slugify from "react-slugify";
import Select from "react-select";

import Form from "../../../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import EmailInput from "../../../../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput";
import NumberInput from "../../../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByLatLng,
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import useAxiosInstance from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { STORE_API } from "../../../../../../../Utilities/APIs/APIs";
import { VStack } from "@chakra-ui/react";
import SingleImageInput from "../../../../../../Partials/Layouts/Forms/FormInputs/SingleImageInput/SingleImageInput";
import BannerImageInput from "../../../../../../Partials/Layouts/Forms/FormInputs/BannerImageInput/BannerImageInput";
import TimeInput from "../../../../../../Partials/Layouts/Forms/TimeInput/TimeInput";

function UpdateStore({
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
  targetID,
}) {
  const axiosInstance = useAxiosInstance();
  const [value, setValue] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [image, setImage] = useState("");
  const [bannerImage, setBannerImage] = useState();
  const [isAPIBusy, setIsAPIBusy] = useState();
  const [address, setAddress] = useState("");
  const [type, setType] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  useEffect(() => {
    async function fetchAndsetStoreInfo() {
      const { data } = await axiosInstance.get(
        STORE_API + "getSellerSinglestore/" + targetID
      );
      setName(data.name);
      setEmail(data.email);
      setMobile(data.mobile);
      setCity(data.city);
      setStreet(data.street);
      setZipCode(data.zipCode);
      setState(data.state);
      setImage(data.image);
      setBannerImage(data.bannerImage);
      setStartTime(data.startTime);
      setEndTime(data.endTime);
      setType(data.type);
      const [latitude, longitude] = await data.coordinates.split(","); // Split coordinates
      setLat(parseFloat(latitude)); // Parse latitude
      setLng(parseFloat(longitude)); // Parse longitude
    }
    fetchAndsetStoreInfo();
  }, [targetID, axiosInstance]);

  console.log("lat and lng is", type);
  useEffect(() => {
    console.log("data", lat, lng);
    const getLatLngAsync = async () => {
      try {
        const results = await geocodeByLatLng({ lat, lng });
        const address = results[0].formatted_address;
        console.log("Address:", results);
        setAddress(address);
      } catch (error) {
        console.error("Error fetching address:", error);
      }
    };
    getLatLngAsync();
  }, [lat, lng]);

  console.log("address", address);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsAPIBusy(true);

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("slug", slugify(name));
    itemData.append("email", email);
    itemData.append("mobile", mobile);
    itemData.append("city", city);
    itemData.append("state", state);
    itemData.append("street", street);
    itemData.append("zipCode", zipCode);
    itemData.append("image", image);
    itemData.append("bannerImage", bannerImage);
    itemData.append("coordinates", `${lat}, ${lng}`);

    itemData.append("startTime", startTime);
    itemData.append("type", type);
    itemData.append("endTime", endTime);
    itemData.append("timezone", "America/New_York");

    const response = await axiosInstance.patch(
      STORE_API + "updateStore/" + targetID,
      itemData
    );

    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
    setIsAPIBusy(false);
  }

  const handleMarkerDrag = (newLat, newLng) => {
    setLat(newLat);
    setLng(newLng);
  };

  // console.log("lat and lng is",lat,lng)

  useEffect(() => {
    const getLatLngAsync = async () => {
      try {
        const results = await geocodeByAddress(value.label);
        console.log("Geocode results:", results);
        const latLng = await getLatLng(results[0]);

        const [place] = await geocodeByPlaceId(results[0].place_id);
        // Extracting the address components
        const addressComponents = place.address_components;

        // Destructure state, city, street, and zipCode from the address components
        const postalCode = addressComponents.find(c => c.types.includes('postal_code'))?.long_name || '';
        const state = addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.long_name || '';
        const city = addressComponents.find(c => c.types.includes('locality') || c.types.includes('administrative_area_level_2'))?.long_name || '';
        const street = addressComponents.find(c => c.types.includes('route'))?.long_name || '';
        console.log("Successfully got latitude and longitude", latLng);
        setLat(latLng.lat);
        setLng(latLng.lng);
        setZipCode(postalCode);
        setState(state);
        setCity(city);
        setStreet(street);
      } catch (error) {
        console.error("Error getting latitude and longitude", error);
      }
    };

    getLatLngAsync();
  }, [value]);

  const options = [
    { value: "Independent Reseller", label: "Independent Reseller" },
    { value: "Sneaker Store", label: "Sneaker Store" },
  ];

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <VStack spacing={"3"} align={"stretch"}>
        <p className="input_field_label caption bold">Update Your Location</p>
        {/* <Map
          zoom={15}
          center={{ lat, lng }}
          marker={{ lat, lng }}
          mcClass="post-map"
          handleClick={(event) => {
            setLat(event.latLng.lat())
            setLng(event.latLng.lng())
          }}

          handleMarkerDrag={handleMarkerDrag}
        /> */}
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
          onLoadFailed={(error) =>
            console.error("Could not inject Google script", error)
          }
          selectProps={{
            value: value,
            onChange: (value) => {
              setValue(value);
            },
            placeholder: `${address}`,
          }}
          autocompletionRequest={{
            componentRestrictions: {
              country: ["us"],
            },
          }}
        />
        <SingleImageInput
          fieldId="1"
          state={image}
          setState={setImage}
          allowUpdateImage
        >
          Logo
        </SingleImageInput>

        <div>
          <p className="input_field_label caption bold">Select Store type</p>
          <Select
            value={[{ value: type, label: type }]}
            options={options}
            onChange={(e) => setType(e.value)}
            placeholder={"Seller Type"}
          />
        </div>
        <ShortTextInput
          value={name}
          // placeholder={`Enter Name`}
          label={"Enter store name"}
          setState={setName}
        />

        <EmailInput
          value={email}
          // placeholder={`Enter Email`}
          label={"Enter Email"}
          setState={setEmail}
        />
        <NumberInput
          value={mobile}
          // placeholder={`Enter Mobile`}
          label={"Enter Store Mobile Number"}
          setState={setMobile}
        />
        {/* <ShortTextInput
          value={state}
          // placeholder={`Enter State`}
          label={"Enter State"}
          setState={setState}
        />
        <ShortTextInput
          value={city}
          // placeholder={`Enter City/Country`}
          label={"Enter City"}
          setState={setCity}
        />

        <ShortTextInput
          value={street}
          // placeholder={`Enter Street`}
          label={"Enter Street"}
          setState={setStreet}
        />
        <ShortTextInput
          value={zipCode}
          // placeholder={`Enter ZipCode`}
          label={"Enter ZipCode"}
          setState={setZipCode}
        /> */}
        <TimeInput
          value={startTime}
          // placeholder={`Enter Start Time`}
          label={" Store Opening Time"}
          setState={setStartTime}
        />
        <TimeInput
          value={endTime}
          // placeholder={` Start Time`}
          label={" Store Closing Time"}
          setState={setEndTime}
        />

        <BannerImageInput
          fieldId={2}
          state={bannerImage}
          setState={setBannerImage}
          allowUpdateImage
          children={"Banner Image (ratio must be 1060px * 500px)"}
        />

        <FormSubmitButton isLoading={isAPIBusy} text="Update Store" />
      </VStack>
    </Form>
  );
}

export default UpdateStore;
