import web3 from "web3";
import { useEffect, useState } from "react";
import { VStack } from "@chakra-ui/react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import EmailInput from "../../../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import SingleSelectDropDown from "../../../../../Partials/Layouts/Forms/FormInputs/SingleSelectDropDown/SingleSelectDropDown";
import { toast } from "react-toastify";

const options = [
  { value: "credit-debit-card", label: "Credit/Debit Card" },
  { value: "bank-account", label: "Bank Account" },
];

function EditPaymentCardInfo({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
  toggleFetch,
}) {
  // {bankName,accountHolderName,accountNumber,routingNumber,mobile,email,bankAddress
  const [paymentMethod, setPaymentMethods] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [isAPIBusy, setIsAPIBusy] = useState();
  const axiosInstance = useAxiosInstance();
  const [account, setAccount] = useState(null);
  const [branchName, setBranchName] = useState("");

  const [creditCardInfo, setCreditCard] = useState({
    cardHolderName: "",
    cardNumber: "",
    cardExpiryDate: "",
    cardCvv: "",
    cardBillingAddress: "",
  });

  const handleFilter = (selectedValue) => {
    setPaymentMethods(selectedValue);
  };

  const handleCreditCardChange = (field, value) => {
    setCreditCard((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleExpiryInput = (value) => {
    value = value.replace(/\D/g, "");
    if (value.length >= 3) {
      value = `${value.slice(0, 2)}/${value.slice(2, 4)}`;
    }
    if (value.length > 5) {
      value = value.slice(0, 5);
    }
    handleCreditCardChange("cardExpiryDate", value);
  };

  useEffect(() => {
    async function fetchAndsetCardInfo() {
      const { data } = await axiosInstance.get("cardInfo/" + targetID);
      if (data?.paymentMethod === "credit-debit-card") {
        setPaymentMethods(data?.paymentMethod);
        setCreditCard({
          cardHolderName: data?.cardHolderName,
          cardNumber: data?.cardNumber,
          cardExpiryDate:
            data?.cardExpiryDate.months !== "" && data?.cardExpiryDate.years
              ? `${data?.cardExpiryDate.months || ""}/${
                  data.cardExpiryDate.years || ""
                }`
              : "",
          cardCvv: data?.cardCvv,
          cardBillingAddress: data?.cardBillingAddress,
        });
      }
      if (data?.paymentMethod === "bank-account") {
        setPaymentMethods(data?.paymentMethod);
        setBankName(data?.bankName);
        setAccountHolderName(data?.accountHolderName);
        setAccountNumber(data?.accountNumber);
        setRoutingNumber(data?.routingNumber);
        setMobile(data?.mobile);
        setEmail(data?.email);
        setBankAddress(data?.bankAddress);
        setAccount(data?.walletAddress);
      }
    }
    fetchAndsetCardInfo();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    if (!paymentMethod) {
      toast.error("Please select payment method");
      return;
    }

    setIsAPIBusy(true);

    const itemData = {
      bankName,
      accountHolderName,
      accountNumber,
      routingNumber,
      mobile,
      email,
      bankAddress,
      walletAddress: account,
    };

    const response = await axiosInstance.patch(
      "cardInfo/" + targetID,
      paymentMethod == "bank-account" ? itemData : creditCardInfo
    );

    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
    setIsAPIBusy(false);
  }

  const connectWallet = async () => {
    console.log("trying to connect wallet");
    if (web3) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccount(accounts[0]);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <VStack spacing={"3"} align={"stretch"}>
        <SingleSelectDropDown
          label={<span className="payment-lable">Select Payment Method :</span>}
          placeholder="Please Select Payment Method"
          value={paymentMethod}
          onChange={handleFilter}
          options={options}
        />

        {paymentMethod && paymentMethod === "bank-account" && (
          <>
            <ShortTextInput
              value={accountHolderName}
              placeholder={`Enter Account Holder Name`}
              setState={setAccountHolderName}
            />
            <ShortTextInput
              value={bankName}
              placeholder={`Enter Bank Name`}
              setState={setBankName}
            />
            <NumberInput
              value={accountNumber}
              placeholder={`Enter Account Number`}
              setState={setAccountNumber}
            />
            <NumberInput
              value={routingNumber}
              placeholder={`Enter Routing Number`}
              setState={setRoutingNumber}
            />
            <ShortTextInput
              value={bankAddress}
              placeholder={`Enter Bank Address`}
              setState={setBankAddress}
            />
            {/* <ShortTextInput
              value={branchName}
              placeholder={`Enter Branch Address`}
              setState={setBranchName}
            /> */}
            <EmailInput
              value={email}
              placeholder={`Enter Email`}
              setState={setEmail}
            />
          </>
        )}
        {paymentMethod && paymentMethod === "credit-debit-card" && (
          <>
            <ShortTextInput
              value={creditCardInfo?.cardHolderName}
              placeholder="Enter Cardholder Name"
              setState={(value) =>
                handleCreditCardChange("cardHolderName", value)
              }
            />
            <ShortTextInput
              value={creditCardInfo?.cardNumber}
              placeholder="Enter Card Number"
              setState={(value) => handleCreditCardChange("cardNumber", value)}
            />

            <input
              className="date-input"
              bg="white"
              size="lg"
              py="4"
              h="auto"
              fontSize="small"
              value={creditCardInfo?.cardExpiryDate}
              placeholder="Enter Expiry Date (MM/YY)"
              onChange={(e) => handleExpiryInput(e.target.value)}
              maxLength={5}
              type="numeric"
            />

            {/* <ShortTextInput
              value={setCreditCard.cardExpiryDate}
              placeholder="Enter Expiry Date (MM/YY)"
              setState={(value) => handleCreditCardChange("cardExpiryDate", formatExpiryDate(value))}
            /> */}
            <ShortTextInput
              value={creditCardInfo?.cardCvv}
              placeholder="Enter CVV"
              setState={(value) => handleCreditCardChange("cardCvv", value)}
            />

            <ShortTextInput
              value={creditCardInfo?.cardBillingAddress}
              placeholder="Enter Billing Address"
              setState={(value) =>
                handleCreditCardChange("cardBillingAddress", value)
              }
            />
          </>
        )}

        <div className="flex-btn">
          {account ? (
            <p>Wallet Address: {account}</p>
          ) : (
            <button
              onClick={connectWallet}
              type="button"
              style={{
                backgroundColor: "#F5841F",
                color: "black",
                padding: "8px",
                width: "100%",
                fontSize: "14px",
                fontWeight: "500",
                border: "none",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
            >
              Connect Crypto Wallet -&gt;
            </button>
          )}
          {/* text={`Update ${paymentMethod === "credit-debit-card" ? "Card" : paymentMethod === "bank-account" ? "Bank" : "Payment"} Info`} */}
          <FormSubmitButton
            isLoading={isAPIBusy}
            text={`Update ${
              paymentMethod === "credit-debit-card"
                ? "Card"
                : paymentMethod === "bank-account"
                ? "Bank"
                : "Payment"
            } Info`}
          />
        </div>
      </VStack>
    </Form>
  );
}

export default EditPaymentCardInfo;
