import web3 from "web3";
import { useEffect, useState } from "react";
import { VStack } from "@chakra-ui/react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import EmailInput from "../../../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import SingleSelectDropDown from "../../../../../Partials/Layouts/Forms/FormInputs/SingleSelectDropDown/SingleSelectDropDown";
import { toast } from "react-toastify";
import LongTextInput from "../../../../../Partials/Elements/Forms/FormInputs/LongTextInput/LongTextInput";

const options = [
  { value: "credit-debit-card", label: "Credit/Debit Card" },
  { value: "bank-account", label: "Bank Account" },
];

function EditProductRequest({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
  toggleFetch,
}) {
  const [productDescription, setProductDescription] = useState("");
  const [isAPIBusy, setIsAPIBusy] = useState(false);
  const  axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchProductRequest() {
      const { data } = await axiosInstance.get("/product/request/" + targetID);
      setProductDescription(data.description);
    }
    fetchProductRequest();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsAPIBusy(true);
    const itemData = {
      productDescription
    };

    const response = await axiosInstance.patch(
      "product/request/" + targetID, itemData
    );

    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
    setIsAPIBusy(false);
  }

  return (

    <Form onSubmit={handleSubmit} hasImage>
      <VStack spacing={"3"} align={"stretch"}>
        <LongTextInput label={"Product Description"} value={productDescription} placeholder={"Please enter product description"} setState={setProductDescription} />
        <FormSubmitButton isLoading={isAPIBusy} text="Update Product" />
      </VStack>
    </Form>
  );
}

export default EditProductRequest;
