import React, { useState, useContext } from "react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { AppContext } from "../../../AppContext/AppContext";

const DateRangePickerMaster = ({ selectedDates, setSelectedDates }) => {
  const { setDashboardFilter } = useContext(AppContext);

  const formatDate = (date) => {
    if (!date) return null;

    // Extract the year, month and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
  };

  const handleDateChange = (newDates) => {
    setSelectedDates(newDates);

    const [fromDate, toDate] = newDates;
    // console.log("fromDate", fromDate);

    // Format the dates using the custom formatDate function
    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);

    // Only make the API call if both fromDate and toDate are not null
    if (formattedFromDate && formattedToDate) {
      setDashboardFilter({
        from: formattedFromDate,
        to: formattedToDate,
      });
    }
  };

  return (
    <div className="date-range-input-main">
      <RangeDatepicker
        className="date-range-input"
        selectedDates={selectedDates}
        onDateChange={handleDateChange}
        propsConfigs={{
          inputProps: {
            placeholder: "MM/DD/YYYY",
          },
        }}
      />
    </div>
  );

  // return (
  //   <RangeDatepicker
  //     selectedDates={selectedDates}
  //     onDateChange={handleDateChange}
  //     propsConfigs={{
  //       inputProps: {
  //         placeholder: 'mm/dd/yyyy',
  //       },
  //     }}
  //   />
  // );
};

export default DateRangePickerMaster;
