import React from "react";
import Select from "react-select";
import { HStack, Text } from "@chakra-ui/react";

const ReactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    width: "249px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px",
  }),
};

const SingleSelectDropDown = ({ label, placeholder, value, onChange, options }) => {
  const handleFilter = (selectedOption) => {
    onChange(selectedOption ? selectedOption?.value : null);
  };

  return (
    <HStack>
      <Text className="form-label">{label}</Text>
      <Select
       className="select-dropdown"
        placeholder={placeholder}
        styles={ReactSelectStyles}
        maxMenuHeight={220}
        menuPlacement="auto"
        isClearable={false}
        value={options.find((option) => option.value === value) || null}
        onChange={handleFilter}
        options={options}
      />
    </HStack>
  );
};

export default SingleSelectDropDown;
