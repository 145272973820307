import { useEffect, useState } from "react";

import web3 from "web3";
import { toast } from "react-toastify";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import EmailInput from "../../../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import { VStack } from "@chakra-ui/react";
import SingleSelectDropDown from "../../../../../Partials/Layouts/Forms/FormInputs/SingleSelectDropDown/SingleSelectDropDown";
import { style } from "framer-motion/client";


const options = [
  { value: "credit-debit-card", label: "Credit/Debit Card" },
  { value: "bank-account", label: "Bank Account" },
];


function CreatePaymentCardInfo({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
}) {
  // {bankName,accountHolderName,accountNumber,routingNumber,mobile,email,bankAddress

  const [paymentMethod, setPaymentMethods] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [branchName, setBranchName] = useState("");
  const [isAPIBusy, setIsAPIBusy] = useState();
  const axiosInstance = useAxiosInstance();
  const [account, setAccount] = useState(null);

  const [creditCardInfo, setCreditCard] = useState(
    {
      cardHolderName: "",
      cardNumber: "",
      cardExpiryDate: "",
      cardCvv: "",
      cardBillingAddress: "",
    }
  );
  
  const handleFilter = (selectedValue) => {
    setPaymentMethods(selectedValue);
  };

  //Credit Card.
  const handleCreditCardChange = (field, value) => {
    setCreditCard((prevState) => ({ ...prevState, [field]: value }));
  };


  // s
  async function handleSubmit(e) {
    e.preventDefault();

    if (!paymentMethod) {
      toast.error("Please select payment method");
      return;
    }
    if (paymentMethod === "credit-debit-card" && (!creditCardInfo.cardHolderName || !creditCardInfo.cardNumber || !creditCardInfo.cardExpiryDate || !creditCardInfo.cardCvv || !creditCardInfo.cardBillingAddress)) {
      toast.error("Please fill all credit card information");
      return;
    }

    if (paymentMethod === "bank-account" && (
      !bankName ||
      !accountHolderName ||
      !accountNumber ||
      !routingNumber ||
      // !mobile ||
      !email ||
      !bankAddress
      // !branchName
    )
    ) {
      toast.error("Please add all field");
      return;
    }
    setIsAPIBusy(true);

    const itemData = {
      bankName,
      accountHolderName,
      accountNumber,
      routingNumber,
      // mobile,
      email,
      bankAddress,
      // branchName,
      paymentMethod,
      walletAddress: account,
    };
    const response = await axiosInstance.post("cardInfo", paymentMethod === "bank-account" ? itemData : { ...creditCardInfo, paymentMethod });

    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
    setIsAPIBusy(false);
  }

  // Call the function to connect MetaMask
  const connectWallet = async () => {
    console.log("trying to connect wallet");
    if (web3) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccount(accounts[0]);
      } catch (error) {
        console.error(error);
      }
    }
  };


  const handleExpiryInput = (value) => {
    value = value.replace(/\D/g, "");
    if (value.length >= 3) {
      value = `${value.slice(0, 2)}/${value.slice(2, 4)}`;
    }
    if (value.length > 5) {
      value = value.slice(0, 5);
    }
    handleCreditCardChange("cardExpiryDate", value);
  };

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <VStack spacing={"3"} align={"stretch"}>

        <SingleSelectDropDown
          className="payment-dropdown"
          label={<span className="payment-lable">Select Payment Method :</span>}
          placeholder="Please Select Payment Method"
          value={paymentMethod}
          onChange={handleFilter}
          options={options}
        />

        {paymentMethod && paymentMethod === "bank-account" && (
          <>
            <ShortTextInput
              value={accountHolderName}
              placeholder="Enter Account Holder Name"
              setState={setAccountHolderName}
            />
            <ShortTextInput
              value={bankName}
              placeholder="Enter Bank Name"
              setState={setBankName}
            />
            <NumberInput
              value={accountNumber}
              placeholder="Enter Account Number"
              setState={setAccountNumber}
            />
            <NumberInput
              value={routingNumber}
              placeholder="Enter Routing Number"
              setState={setRoutingNumber}
            />
            <ShortTextInput
              value={bankAddress}
              placeholder="Enter Bank Address"
              setState={setBankAddress}
            />
            {/* <ShortTextInput
              value={branchName}
              placeholder="Enter Branch Name"
              setState={setBranchName}
            /> */}
            <EmailInput
              value={email}
              placeholder="Enter Email"
              setState={setEmail}
            />
            {/* <NumberInput
              value={mobile}
              placeholder="Enter Mobile"
              setState={setMobile}
            /> */}
          </>
        )}


        {paymentMethod && paymentMethod === "credit-debit-card" && (
          <>
            <ShortTextInput
              value={setCreditCard.cardHolderName}
              placeholder="Enter Cardholder Name"
              setState={(value) => handleCreditCardChange("cardHolderName", value)}
            />

            <NumberInput
              value={setCreditCard.cardNumber}
              placeholder="Enter Card Number"
              setState={(value) => handleCreditCardChange("cardNumber", value)}
            />

            <input
              className="date-input"
              bg="white"
              size="lg"
              py="4"
              h="auto"
              fontSize="small"
              value={creditCardInfo.cardExpiryDate}
              placeholder="Enter Expiry Date (MM/YY)"
              onChange={(e) => handleExpiryInput(e.target.value)}
              maxLength={5}
              type="numeric"
            />

            {/* <ShortTextInput
              value={setCreditCard.cardExpiryDate}
              placeholder="Enter Expiry Date (MM/YY)"
              setState={(value) => handleCreditCardChange("cardExpiryDate", formatExpiryDate(value))}
            /> */}
            <ShortTextInput
              value={setCreditCard.cardCvv}
              placeholder="Enter CVV"
              setState={(value) => handleCreditCardChange("cardCvv", value)}
            />

            <ShortTextInput
              value={setCreditCard.cardBillingAddress}
              placeholder="Enter Billing Address"
              setState={(value) => handleCreditCardChange("cardBillingAddress", value)}
            />

          </>
        )}
        <div className="flex-btn">
          {account ? (
            <p>Wallet Address: {account}</p>
          ) : (
            <button
              onClick={connectWallet}
              type="button"
              style={{
                backgroundColor: "#F5841F",
                color: "black",
                padding: "8px",
                width: "100%",
                fontSize: "14px",
                fontWeight: "500",
                border: "none",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
            >
              Connect Crypto Wallet -&gt;
            </button>
          )}
          <FormSubmitButton isLoading={isAPIBusy} text={`Create ${paymentMethod === "credit-debit-card" ? "Card" : paymentMethod === "bank-account" ? "Bank" : "Payment"} Info`} />
        </div>
      </VStack>
    </Form>
  );
}

export default CreatePaymentCardInfo;
