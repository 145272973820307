import {useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { VStack } from "@chakra-ui/react";
import LongTextInput from "../../../../../Partials/Elements/Forms/FormInputs/LongTextInput/LongTextInput";


function CreateProductRequest({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
}) {

  const [productDescription, setProductDescription] = useState("");
  const [isAPIBusy,setIsAPIBusy] = useState(false);
  const  axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();
    setIsAPIBusy(true);
    const itemData = {
      productDescription,
    };

    const response = await axiosInstance.post("product/request", itemData );

    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
    setIsAPIBusy(false);
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <VStack spacing={"3"} align={"stretch"}>
          <LongTextInput label={"Product Description"} value ={productDescription} placeholder={"Please enter product description"} setState={setProductDescription} />
          <FormSubmitButton isLoading={isAPIBusy} text="Product Request" />
      </VStack>
    </Form>
  );
}

export default CreateProductRequest;
