import React, { useContext } from "react";
import { AppContext } from "../../../AppContext/AppContext";

export default function ResetButton({ setEarningFilter, setSelectedDates }) {
  const { setDashboardFilter, setEarning } = useContext(AppContext);

  const resetFilters = () => {
    setSelectedDates([null, null]);
    setDashboardFilter({ from: null, to: null });
    setEarning("all");
    setEarningFilter("all");
  };

  return (
      <button onClick={resetFilters} className="primary-btn">
        Reset
      </button>
  );
}
