import React, { useState } from "react";
import Modal from "../../../../../Partials/Elements/Modal/Modal";
import { Form } from "formik";
import AsyncSelect from "react-select/async";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import BannerImageInput from "../../../../../Partials/Layouts/Forms/FormInputs/BannerImageInput/BannerImageInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { VStack } from "@chakra-ui/react";
import CreateNewProduct from "../../CreateProduct/CreateNewProduct";
// import CreateProduct from "../../CreateProduct/CreateProduct";
// import UpdateProduct from "../../UpdateProduct";
// import ArchiveItem from "../../../../../Partials/Layouts/ArchiveItem/ArchiveItem";
// import ViewProduct from "../../ViewProduct";
// import { PRODUCT_API } from "../../../../../../Utilities/APIs/APIs";

export default function ProductModal({
  showModal,
  handleClose,
}) {

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [colorWay, setColorWay] = useState([]);
  const [category, setCategory] = useState(null);
  const [sku, setSku] = useState("");
  const [productId, setProductID] = useState(null);
  const [description, setDescription] = useState("");
  const [cardImage, setCardImage] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [images, setImages] = useState([]);

  
  return (
    <>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={'Create New Product'}
        >
            <CreateNewProduct
            handleClose={handleClose}
            />

        </Modal>
      )}
    </>
  );
}
