import { Text } from "@chakra-ui/react";
import { AppContext } from "../../../../../../AppContext/AppContext";
import { useContext } from "react";

export default function SidebarInfo() {
  const { seller } = useContext(AppContext);

  return (
    <>
      <div>
        <Text fontSize="medium" pt="15px">
          Welcome
          <Text as="span" fontWeight="semibold">
          &nbsp; {seller ? seller.name.charAt(0).toUpperCase() + seller.name.slice(1) : ""}
          </Text>
        </Text>


      </div>

    </>
  )
}
