import React from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { IoChevronDown } from "react-icons/io5";
import CRUDTable from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableRow from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import EditButton from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import { Chakra_Styles } from "../../../../../MyStore/MyStore";
import ViewButton from "../../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";

export default function ProductRequestInfoTable({
  productRequest,
  setShowModal,
  setTargetID,
  setShowViewSection,
  setShowUpdateForm,
}) {
  console.log("productRequest",productRequest)
  return (
    <div>
      <CRUDTable>
        <tbody>
          {productRequest &&
            productRequest?.map((product) => (
              <CRUDTableRow key={product?._id}>
                <ShortTextCell
                  text={product?.description}
                  cellStyle={{ fontWeight: "600" }}
                />
                <ShortTextCell
                  text={product?.isPosted ? "Posted" : "Pending"}
                  cellStyle={{ fontWeight: "600" }}
                />
                <ShortTextCell
                  text={new Date(product.createdAt).toLocaleDateString()}
                  cellStyle={{ fontWeight: "600" }}
                />

                <td>
                  <Menu>
                    <MenuButton
                      textAlign={"left"}
                      as={Button}
                      rightIcon={<IoChevronDown />}
                      size="md"
                      bg="#322A5D30"
                      // color='white'
                      _hover={{
                        bg: "#322A5D",
                        color: "white",
                      }}
                      _active={{
                        bg: "#322A5D",
                        color: "white",
                      }}
                      isDisabled={product?.isPosted ? true : false}
                    >
                      Actions
                    </MenuButton>
                    <MenuList>
                      {/* <ViewButton
                        setShowModal={setShowModal}
                        setShowViewSection={setShowViewSection}
                        targetID={product._id}
                        setTargetID={setTargetID}
                      >
                        <Text {...Chakra_Styles.actionMenuItem}>
                          Payment Info
                        </Text>
                      </ViewButton> */}
                      <MenuDivider />

                      <EditButton
                        setShowModal={setShowModal}
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={product?._id}
                        setTargetID={setTargetID}
                      >
                        <Text {...Chakra_Styles.actionMenuItem}>
                          Edit Product Request Info
                        </Text>
                      </EditButton>
                    </MenuList>
                  </Menu>
                </td>
              </CRUDTableRow>
            ))}
        </tbody>
      </CRUDTable>
    </div>
  );
}
