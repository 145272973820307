import { useState, useContext, useEffect } from "react";
import { VStack } from "@chakra-ui/react";

import ProductRequestHeader from "./ProductRequestHeader/ProductRequestHeader";
import ProductRequestInfoTable from "./ProductRequestInfoTable/ProductRequestInfoTable";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import ProductRequestModel from "./ProductRequestModel/ProductRequestModel";


function ProductRequest() {
  // const [search, setSearch] = useState("");
  const [productRequest, setProductRequestData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  // const { seller } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetStores() {
      const { data } = await axiosInstance.get(`product/getAllProductRequest`);
      console.log("product request data:", data.data);
      setProductRequestData(data.data);
    }

    fetchAndSetStores();
  }, [axiosInstance, toggleFetch]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <VStack w="full" align="stretch">
        <ProductRequestHeader
          productRequest={productRequest}
          setShowCreateForm={setShowCreateForm}
          setShowModal={setShowModal}
        />
        <ProductRequestInfoTable
          productRequest={productRequest}
          setShowModal={setShowModal}
          setTargetID={setTargetID}
          setShowViewSection={setShowViewSection}
          setShowUpdateForm={setShowUpdateForm}
        />
      </VStack>

      <ProductRequestModel
        showCreateForm={showCreateForm}
        showUpdateForm={showUpdateForm}
        showViewSection={showViewSection}
        showModal={showModal}
        targetID={targetID}
        handleClose={handleClose}
        triggerFetch={triggerFetch}
        setShowCreateForm={setShowCreateForm}
        setShowModal={setShowModal}
        setShowUpdateForm={setShowUpdateForm}
      />
    </>
  );
}

export default ProductRequest;
