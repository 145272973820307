import { useContext, useEffect, useState } from "react";
import Form from "../../../../Partials/Layouts/Forms/Form";

import { useNavigate } from "react-router-dom";
import { Box, VStack } from "@chakra-ui/react";
import { AppContext } from "../../../../../AppContext/AppContext";
import RegTopSection from "./components/TopSection/RegTopSection";
import BottomSection from "./components/RegBottomSection/BottomSection";

import "./RegistrationForm.css";

const options = [
  { value: "Sneaker Store", label: "Sneaker Store" },
  { value: "Independent Reseller", label: "Independent Reseller" },
  { value: "Fashion Brand", label: "Fashion Brand" },
  // { value: "Other", label: "Other" },

];

export const ReactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px",
  }),
};

function RegistrationForm() {
  const { register, seller, apiState } = useContext(AppContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [type, setType] = useState("");
  const [bussinessName, setBussinessName] = useState("");
  const [bussinessAddress, setBussinessAddress] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [socialMediaLink, setSocialMediaLink] = useState("");
  const [otherType, setOtherType] = useState("");


  const [dp, setDp] = useState(null);

  // Error states for each input field
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [bussinessNameError, setBussinessNameError] = useState("");
  const [bussinessAddressError, setBussinessAddressError] = useState("");
  const [websiteUrlError, setWebsiteUrlError] = useState("");
  const [socialMediaLinkError, setSocialMediaLinkError] = useState("");
  const [otherTypeError, setOtherTypeError] = useState("");

  const [dpError, setDpError] = useState("");

  // Validation function
  function validateInputs() {
    let valid = true;

    if (!dp) {
      setDpError("Display Picture is required");
      valid = false;
    } else {
      setDpError("");
    }

    // Validate name
    if (!name) {
      setNameError("Name is required");
      valid = false;
    } else {
      setNameError("");
    }

    // Validate email
    if (!email) {
      setEmailError("Email is required");
      valid = false;
    } else {
      setEmailError("");
    }

    // Validate password
    if (!password) {
      setPasswordError("Password is required");
      valid = false;
    } else {
      setPasswordError("");
    }

    // Validate address
    if (!address) {
      setAddressError("Address is required");
      valid = false;
    } else {
      setAddressError("");
    }

    // Validate phone number
    if (!phoneNumber) {
      setPhoneNumberError("Phone number is required");
      valid = false;
    } else {
      setPhoneNumberError("");
    }

    // Validate bussiness name
    if (!bussinessName) {
      setBussinessNameError("Bussiness name is required");
      valid = false;
    } else {
      setBussinessNameError("");
    }
    
    // Validate bussiness address
    if (!bussinessAddress) {
      setBussinessAddressError("Bussiness address is required");
      valid = false;
    } else {
      setBussinessAddressError("");
    }

    // Validate website URL
    if (!websiteUrl) {
      setWebsiteUrlError("Website URL is required");
      valid = false;
    } else {
      setWebsiteUrlError("");
    }

    // Validate social media link
    if (!socialMediaLink) {
      setSocialMediaLinkError("Social media link is required");
      valid = false;
    } else {
      setSocialMediaLinkError("");
    }

    // Validate type
    if (!type) {
      setTypeError("Type is required");
      valid = false;
    } else {
      setTypeError("");
    }

    // Validate other type
    if (type == 'Other' && !otherType) {
      setOtherTypeError("Other type is required");
      valid = false;
    } else {
      setOtherTypeError("");
    }

    return valid;
  }

  const fieldId = 2;
  const navigate = useNavigate();

  const [imagePreview, setImagePreview] = useState({ preview: "" });
  const handleChange = (e) => {
    setDp(e.target.files[0]);
    setImagePreview({
      preview: URL.createObjectURL(e.target.files[0]),
    });
  };

  function triggerImageModal() {
    const input = document.getElementById(`fileInput${fieldId}`);
    input.click();
  }

  const menuPortalTarget = document.getElementById("root");

  const handleType = (selectedOption) => {
    setType(selectedOption.value);
    if (selectedOption.value) {
      setTypeError("");
    }
  };

  useEffect(() => {
    if (dp) {
      setDpError("");
    }
  }, [dp]);

  async function handleSubmit(e) {
    e.preventDefault();

    // Validate inputs before submitting
    if (!validateInputs()) {
      return;
    }
    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("email", email.toLowerCase());
    itemData.append("password", password);
    itemData.append("address", address);
    itemData.append("phoneNumber", phoneNumber);
    itemData.append("type", type);
    itemData.append("bussinessName", bussinessName);
    itemData.append("bussinessAddress",bussinessAddress);
    itemData.append("websiteUrl",websiteUrl);
    itemData.append("socialMediaLink",socialMediaLink);
    itemData.append("otherType",otherType);

    
    itemData.append("dp", dp);

    register(itemData);
    setName("");
    setEmail("");
    setPassword("");
    setAddress("");
    setPhoneNumber("");
    setType("");
    setBussinessName("");
    setBussinessAddress("");
    setWebsiteUrl("");
    setSocialMediaLink("");
    setOtherType("");
    setDp(null);
  }

  useEffect(() => {
    if (seller) {
      navigate("verify-mail-instruction");
    }
  }, [seller, navigate]);

  return (
    <div style={{ width: "30vw", margin: "0 auto", overflow: "scroll" }}>
      <Form onSubmit={handleSubmit}>
        <VStack w="full" align="stretch" h="max-content">
          <RegTopSection
            name={name}
            email={email}
            imagePreview={imagePreview}
            triggerImageModal={triggerImageModal}
            handleChange={handleChange}
            dpError={dpError}
            fieldId={fieldId}
          />
          <Box h="5" />

          <BottomSection
            name={name}
            email={email}
            password={password}
            address={address}
            phoneNumber={phoneNumber}
            type={type}
            bussinessName={bussinessName}
            bussinessAddress={bussinessAddress}
            websiteUrl={websiteUrl}
            socialMediaLink={socialMediaLink}
            otherType={otherType}
            options={options}
            handleType={handleType}
            nameError={nameError}
            emailError={emailError}
            passwordError={passwordError}
            addressError={addressError}
            phoneNumberError={phoneNumberError}
            bussinessNameError={bussinessNameError}
            bussinessAddressError={bussinessAddressError}
            websiteUrlError={websiteUrlError}
            socialMediaLinkError={socialMediaLinkError}
            otherTypeError={otherTypeError}
            typeError={typeError}
            setName={setName}
            setEmail={setEmail}
            setPassword={setPassword}
            setAddress={setAddress}
            setPhoneNumber={setPhoneNumber}
            setBussinessName={setBussinessName}
            setBussinessAddress={setBussinessAddress}
            setWebsiteUrl={setWebsiteUrl}
            setSocialMediaLink={setSocialMediaLink}
            setOtherType={setOtherType}
            setNameError={setNameError}
            setEmailError={setEmailError}
            setPasswordError={setPasswordError}
            setAddressError={setAddressError}
            setPhoneNumberError={setPhoneNumberError}
            setTypeError={setTypeError}
            setBussinessAddressError={setBussinessNameError}
            setBussinessNameError={setBussinessNameError}
            setWebsiteUrlError={setWebsiteUrlError}
            setSocialMediaLinkError={setSocialMediaLinkError}
            setOtherTypeError={setOtherTypeError}
            apiState={apiState}
            menuPortalTarget={menuPortalTarget}
            ReactSelectStyles={ReactSelectStyles}
          />
        </VStack>
      </Form>
    </div>
  );
}

export default RegistrationForm;
