import React from "react";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import { STORE_API } from "../../../../../Utilities/APIs/APIs";
import CreateStore from "./components/CreateStore/CreateStore";
import UpdateStore from "./components/UpdateStore/UpdateStore";
import ArchiveItem from "../../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import ViewStore from "../../ViewStore/ViewStore";

export default function MyStoreModal({
  showModal,
  handleClose,
  showCreateForm,
  showUpdateForm,
  showDeleteSection,
  showViewSection,
  setShowCreateForm,
  setShowUpdateForm,
  setShowModal,
  setShowDeleteSection,
  targetID,
  triggerFetch,
  seller,
  archive,
}) {
  return (
    <>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Store`) ||
            (showUpdateForm && `Update Store `) ||
            (showDeleteSection && `Delete Store`) ||
            (showViewSection && `View Store`)
          }
        >
          {showCreateForm && (
            <CreateStore
              seller={seller}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateStore
              seller={seller}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <ArchiveItem
              api={STORE_API + "archiveStore/"}
              targetID={targetID}
              seller={seller}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}

          {showViewSection && <ViewStore targetID={targetID} seller={seller} />}
        </Modal>
      )}
    </>
  );
}
