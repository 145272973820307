import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useContext } from "react";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "./Partials/Sections/SideBar/SideBar";
import MainScreen from "../Components/Screens/MainScreen/MainScreen";
import { AppContext } from "../AppContext/AppContext";
import "./App.css";
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultConfig,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
} from 'wagmi/chains';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";


const config = getDefaultConfig({
  appName: 'My RainbowKit App',
  projectId: '6557102fa2cb89ed9979d39bbda7b717',
  chains: [mainnet, polygon, optimism, arbitrum, base],
  ssr: true,
});

function App() {
  const queryClient = new QueryClient();
  const { seller } = useContext(AppContext);
  return (
    <>
      <BrowserRouter>
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <RainbowKitProvider>
              <main className={`main ${seller ? "seller" : "auth"}`}>
                {seller && <Sidebar />}
                <MainScreen seller={seller} />
              </main>
            </RainbowKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </BrowserRouter >
      <ToastContainer />
    </>
  );
}

export default App;
