import React from "react";
import Modal from "../../../../Partials/Elements/Modal/Modal";

export default function OrderModal({ showModal, handleClose, trackingUrl }) {
  console.log(trackingUrl, "trackingUrl");
  return (
    <div>
      {showModal && (
        <Modal handleClose={handleClose} modalHeading={`Order Tracking`}>
          {/* <ViewOrderDetails targetID={targetID} /> */}
          <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
            <iframe
              src={trackingUrl}
              title="EasyPost Tracking"
              style={{ width: "100%", height: "100%", border: "none" }}
              allowFullScreen
            ></iframe>
          </div>
        </Modal>
      )}
    </div>
  );
}
